import {FC, useMemo, useState} from "react";
import classNames from './Drawer.module.css';
import {IconButton} from "@dolbyio/comms-uikit-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export interface PanelHeaderProps {
  participantsCount: number;
  closePanelHandler?: () => void;
  inviteFriends?: () => void;
  tabIndex: boolean;
}

const PanelHeader: FC<PanelHeaderProps> = (props) => {
  const {
    participantsCount = 0,
    closePanelHandler = () => undefined,
    inviteFriends = () => undefined,
    tabIndex,
  } = props;

  const [isShowText, setIsText] = useState<boolean>(false);

  const styleSuccessText = useMemo(() => {
    return isShowText ? classNames.successText : classNames.hideSuccessText;
  }, [isShowText])

  setTimeout(() => {
    setIsText(false);
  }, 1000);

  return (
    <div>
      <div
        className={classNames.panelHeader}
      >
        <div>People: {participantsCount}</div>
        <IconButton
          variant="circle"
          icon="close"
          size="xs"
          onClick={closePanelHandler}
        />
      </div>
      <CopyToClipboard text={window.location.href} onCopy={() => setIsText(true)}>
        <div className={classNames.inviteFriends}>
          <IconButton
            icon="participants"
            onClick={inviteFriends}
            size="s"
            variant="circle"
            backgroundColor="blue"
          />
          <div>Invite friends</div>
        </div>
      </CopyToClipboard>
      <div className={styleSuccessText}>Copy link!</div>
      {tabIndex && <><div className={classNames.call}>in Call</div>
        <div className={classNames.divider} /></>}
    </div>
  );
};

export default PanelHeader;
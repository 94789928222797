import {Connection, Connection_config, Connection_id, Room_id} from "./Connection";

export class Client {
    private _connection: Connection;
    private _on_joined_room?: (client_id: Connection_id, room_id: Room_id, participants: Connection_id[]) => any;
    private _on_left_room?: (client_id: Connection_id) => any;
    private _on_error?: (ev: any) => any;

    private constructor(connection: Connection) {
        this._connection = connection;
    }

    static create(conf: Connection_config): Promise<Client> {
        return Connection.create(conf).then((connection: Connection) => {
            return new Client(connection);
        });
    }

    get id() {
        return this._connection.id;
    }

    close() {
        this._connection.close();
    }

    on_joined_room(listener: (client_id: Connection_id, room_id: Room_id, participants: Connection_id[]) => any) {
        this._on_joined_room = listener;
    }

    on_left_room(listener: (client_id: Connection_id) => any) {
        this._on_left_room = listener;
    }

    on_error(listener: (ev: any) => any) {
        this._on_error = listener;
    }

    on_ws_message(listener: (ev: any) => any, options?: boolean | AddEventListenerOptions) {
        this._connection.on_ws_message((ev: MessageEvent) => {
            const data = JSON.parse(ev.data);
            if (data.type === "error") {
                this._on_error?.(data);
            } else if (data.event === "joined-room") {
                this._on_joined_room?.(data.body["connection-id"], data.body["room-id"], data.body["participants"]);
            } else if (data.event === "left-room") {
                this._on_left_room?.(data.body["connection-id"]);
            } else {
                listener(data);
            }
        }, options);
    }

    on_dc_message(listener: (ev: any) => any) {
        this._connection.on_dc_message((ev: MessageEvent) => {
            const data = JSON.parse(ev.data);
            listener(data);
        });
    }

    join_room(room_id: Room_id) {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("join-room", {"room-id": room_id})));
    }

    leave_room() {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("leave-room", {})));
    }

    broadcast_ws(msg: any) {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("serverwide-broadcast", {message: msg})));
    }

    broadcast_ws_from(msg: any) {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("serverwide-broadcast-from", {message: msg})));
    }

    broadcast_room_ws(room_id: Room_id, msg: any) {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("roomwide-broadcast", {
            "room-id": room_id,
            message: msg,
        })));
    }

    broadcast_room_ws_from(msg: any) {
        this._connection.send_ws(JSON.stringify(Client.make_action_message("roomwide-broadcast-from", {message: msg})));
    }

    broadcast_dc(msg: any) {
        this._connection.send_dc(JSON.stringify(Client.make_action_message("serverwide-broadcast", {message: msg})));
    }

    broadcast_dc_from(msg: any) {
        this._connection.send_dc(JSON.stringify(Client.make_action_message("serverwide-broadcast-from", {message: msg})));
    }

    broadcast_room_dc(room_id: Room_id, msg: any) {
        this._connection.send_dc(JSON.stringify(Client.make_action_message("roomwide-broadcast", {
            "room-id": room_id,
            message: msg,
        })))
        ;
    }

    broadcast_room_dc_from(msg: any) {
        this._connection.send_dc(JSON.stringify(Client.make_action_message("roomwide-broadcast-from", {message: msg})));
    }

    private static make_action_message(action: string, body: any) {
        return {
            protocol: "pluto-server-api",
            version: "0.0.1",
            type: "action",
            action: action,
            body: body,
        };
    }
}
import {FC, ReactNode, useMemo} from "react";
import classNames from './Tab.module.css';

export interface TabModuleProps {
  isSelected: boolean;
  width: string;
  onChangeContent: () => void;
  text: string | ReactNode;
}

const TabModule: FC<TabModuleProps> = (props) => {
  const {
    width = '100%',
    text = 'tab',
    isSelected = false,
    onChangeContent = () => undefined,
  } = props;

  const styles = useMemo(() => {
    const selectedStyle = isSelected && classNames.selectedTab;
    return [
      classNames.tab,
      selectedStyle,
    ].join(' ');
  }, [isSelected]);

  return (
    <div
      style={{ maxWidth: width }}
      onClick={onChangeContent}
      className={styles}
    >
      {text}
    </div>
  );
};

export default TabModule;
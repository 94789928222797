import Modal from "@mui/material/Modal";
import { FC, ReactNode } from "react";
import classNames from './Modal.module.css';

export interface CustomModal {
  children: ReactNode;
  isOpen: boolean;
  onCLoseModal?: () => void;
}

const CustomModal: FC<CustomModal> = (props) => {
  const {
    isOpen = false,
    children,
    onCLoseModal = () => undefined,
  } = props;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={onCLoseModal}
      closeAfterTransition
      className={classNames.modal}
    >
      <div className={classNames.wrapper}>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
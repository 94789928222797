import classNames from './ParticipantInfo.module.css';
import {FC, useEffect} from "react";

export interface ParticipantInfoProps {
  name?: string;
}

const ParticipantInfo: FC<ParticipantInfoProps> = (props) => {
  const { name } = props;

  const userInfo = {
    avatarInfo: name && name[0].toUpperCase(),
    name,
  };

  return (
    <div className={classNames.participantsInfo}>
      <div className={classNames.avatarInfo}>{userInfo.avatarInfo}</div>
      <div className={classNames.participantName}>{userInfo.name}</div>
    </div>
  );
};

export default ParticipantInfo;
import { FC, useEffect } from 'react';
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import {DefaultGenerics, StreamChat} from "stream-chat";
import classNames from './TextChat.module.css';

export interface TextChatProps {
  chatClient: StreamChat<DefaultGenerics> | null;
  channel: any;
  initializeChat: () => void;
}

const TextChat: FC<TextChatProps> = (props) => {
  const {
    chatClient,
    initializeChat = () => undefined,
    channel,
  } = props;

  useEffect(() => {
    initializeChat();
  }, []);

  if (chatClient === null) return <div>Loding...</div>

  return (
    <div className={classNames.root}>
      <div className={classNames.internalBLock}>
        <Chat client={chatClient}>
          <Channel channel={channel}>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </div>
    </div>
);
  };
export default TextChat;
import './App.css';
import Demo from "./containers/Demo/Demo";
import {BrowserRouter} from "react-router-dom";
import CommsProvider from "./context/CommsProvider/CommsProvider";
import {useCallback, useEffect, useState} from "react";
import VoxeetSDK from "@voxeet/voxeet-web-sdk";
import getClientToken from "./api/dolby/auth/getClientToken";
import UserProvider from "./context/UserProvider/UserProvider";

type DolbyIoWindow = {
  dolbyio: {
    isInitialized: boolean;
  };
}

const dioWindow = window as Window & typeof globalThis & DolbyIoWindow;
dioWindow.dolbyio = {
  isInitialized: false,
};

const App = () => {
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const getRefreshToken = useCallback(() => {
    getClientToken()
      .then((response) => {
        setToken(response.access_token);
        setRefreshToken(response.refresh_token);
        return response.refresh_token;
      });
  }, []);

  useEffect(() => {
    getRefreshToken();
  }, []);

  useEffect(() => {
    if (token && refreshToken)
    VoxeetSDK.initializeToken(token, getRefreshToken)
  }, [token, refreshToken])

  return (
    <BrowserRouter>
      <CommsProvider>
        <UserProvider>
          <Demo />
        </UserProvider>
      </CommsProvider>
    </BrowserRouter>
  );
}

export default App;

import { useState} from "react";
import {Channel, DefaultGenerics, StreamChat} from "stream-chat";

export interface UseCreateTextChatType {
  userId: string;
  roomId: string;
}

export interface UseCreateTextChatResult {
  initializeChat: () => void;
  chatClient: StreamChat<DefaultGenerics> | null;
  channel: Channel<DefaultGenerics> | null;
}

const useCreateTextChatRoom = (props: UseCreateTextChatType): UseCreateTextChatResult => {
  const {
    userId = '1',
    roomId,
  } = props;

  const [channel, setChannel] = useState<Channel<DefaultGenerics> | null>(null);
  const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null >(null);
  const initializeChat = async () => {
    const chatClient = await StreamChat.getInstance('t3n9sf8ctbve');
    await chatClient.connectUser(
      {
        id: userId,
        name: userId,
      },
      chatClient.devToken(userId),
    );
    const channel = chatClient.channel('messaging', roomId, {
      image: 'https://klike.net/uploads/posts/2021-04/1618815701_51.jpg',
      name: `Meet ${roomId}`,
    });

    channel.create();

    channel.watch();
    setChannel(channel);
    setChatClient(chatClient);
  };

  return {
    initializeChat,
    channel,
    chatClient,
  };
};

export default useCreateTextChatRoom;
import {FC, ReactNode} from "react";

export interface TabPanelProps {
  index: number;
  tabIndex: number;
  children: ReactNode;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const {
    tabIndex = 1,
    index = 1,
    children,
  } = props;

  if (index !== tabIndex) return (<></>)

  return (
    <>
      {children}
    </>
  );
};

export default TabPanel;
import {FC, ReactNode} from "react";

export interface ParticipantsProps {
  children: ReactNode;
}

const Participants: FC<ParticipantsProps> = ({ children }) => {

  return (
    <div style={{ width: 500 }}>
      {children}
    </div>
  );
};

export default Participants;
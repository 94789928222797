import {FC, useMemo} from "react";
import {IconButton} from "@dolbyio/comms-uikit-react";
import useAudio from "../../hooks/useAudio";
import {ParticipantStatus} from "../../types/Participant";

type ParticipantMuteButtonProps = {
  participant: any;
  size?: 's' | 'm';
  participantsStatus: {[p: string]: ParticipantStatus};
};

const ParticipantsMuteButton: FC<ParticipantMuteButtonProps> = ({ participant, size = 'm', participantsStatus }) => {

  const { startParticipantAudio, stopParticipantAudio } = useAudio();
  const { isLocalAudio, isRemoteAudio } = participantsStatus[participant.id] || {};


  const iconName = useMemo(() => {
    if (isLocalAudio) {
      return 'speaker';
    }
    return 'speakerOff';
  }, [isLocalAudio]);

  const backgroundColor = useMemo(() => {
    if (!isRemoteAudio) {
      return 'grey.900';
    }
    if (isLocalAudio) {
      return 'grey.800';
    }
    return 'white';
  }, [isLocalAudio, isRemoteAudio]);

  const iconColor = useMemo(() => {
    if (!isRemoteAudio) {
      return 'grey.300';
    }
    if (isLocalAudio) {
      return 'white';
    }
    return 'primary.500';
  }, [isLocalAudio, isRemoteAudio]);

  return (
    <IconButton
      testID="ParticipantMuteButton"
      icon={iconName}
      backgroundColor={iconColor}
      iconColor={backgroundColor}
      size={size}
      onClick={isLocalAudio ? () => stopParticipantAudio(participant) : () => startParticipantAudio(participant)}
      disabled={!isRemoteAudio}
    />
  );
};

export default ParticipantsMuteButton;
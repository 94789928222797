import {FC, ReactNode, useEffect} from "react";
import classNames from './Participant.module.css';
import ParticipantInfo from "./ParticipantInfo/ParticipantInfo";
import ParticipantSettings from "./ParticipantSettings/ParticipantSettings";
import {Participant} from "@voxeet/voxeet-web-sdk/types/models/Participant";

export interface ParticipantsType {
  ParticipantInfo: typeof ParticipantInfo;
  ParticipantsSettings: typeof ParticipantSettings;
}

export interface ParticipantsProps {
  children: ReactNode;
  onParticipantClick?: () => void;
}

const ParticipantComponent: FC<ParticipantsProps> & ParticipantsType = (props ) => {
  const {
    onParticipantClick = () => undefined,
    children,
  } = props;

  return (
    <div
      onClick={onParticipantClick}
      className={classNames.participants}
    >
      {children}
    </div>
  );
};

ParticipantComponent.ParticipantInfo = ParticipantInfo;
ParticipantComponent.ParticipantsSettings = ParticipantSettings;

export default ParticipantComponent;
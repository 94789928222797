import {FC, ReactNode, useMemo} from "react";
import classNames from './Tab.module.css';
import TabModule from "./TabModule";

export type TabType = {
  TabModule: typeof TabModule;
}

export interface TabProps {
  direction: 'vertical' | 'horizontal';
  children: ReactNode;
  width: string;
}

const Tab: FC<TabProps> & TabType = (props) => {
  const {
    children,
    direction = 'vertical',
    width,
  } = props;

  const styleTab = useMemo(() => {
    const styleDirection = classNames[`menuDirection_${direction}`]
    return [
      classNames.menu,
      styleDirection,
    ].join(' ');
  }, []);

  return (
    <div
      style={{ maxWidth: width }}
      className={styleTab}>
      {children}
    </div>
  );
};

Tab.TabModule = TabModule;

export default Tab;
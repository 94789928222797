import {FC, useContext, useEffect, useState,} from 'react';
import classNames from './ParticipantSettings.module.css';
import { IconButton} from "@dolbyio/comms-uikit-react";
import {Participant} from "@voxeet/voxeet-web-sdk/types/models/Participant";
import LocalToggleAudioButton from "../../../../../components/LocalAudioButton/LocalToggleAudioButton";
import ParticipantsMuteButton from "../../../../../components/ParticipansMuteButton/ParticipantsMuteButton";
import {ParticipantStatus} from "../../../../../types/Participant";
import {CommsContext} from "../../../../../context/CommsProvider/CommsProvider";
import VoxeetSDK from "@voxeet/voxeet-web-sdk";

export interface ParticipantsSettingsProps {
  participant: Participant;
  participantsStatus: {[p: string]: ParticipantStatus};
  addIsSpeakingListener: (participant: Participant) => () => void
  onKickParticipant: () => void;
  isNew: boolean;
}

const ParticipantSettings: FC<ParticipantsSettingsProps> = (props) => {
  const {
    onKickParticipant = () => undefined,
    participant,
    isNew = false,
  } = props;
  const [isLocal, setIsLocal] = useState<boolean>(false);
  const {
    addIsSpeakingListener,
    participantsStatus
  } = useContext(CommsContext);

  useEffect(() => {
    setIsLocal(VoxeetSDK.session.participant.id === participant.id);
    return addIsSpeakingListener(participant);
  }, []);

  return (
    <div className={classNames.settings}>
      <div className={classNames.actionButton}>
        {isLocal ? (
          <LocalToggleAudioButton size="s" participant={participant} />
        ) : (
          <ParticipantsMuteButton participantsStatus={participantsStatus} size="s" participant={participant} />
        )}
      </div>
      {(!isLocal && isNew) && <IconButton size="s" icon="close" style={{background: '#d90be3'}} onClick={onKickParticipant}/>}
    </div>
  );
};

export default ParticipantSettings;
import {ChangeEvent, FC, useCallback, useContext, useEffect, useState} from "react";
import ThreejsDemo from "../../ThreejsDemo";
import ChatPanel from "../ChatPanel/ChatPanel";
import BroadcastDemo from "../../BroadcastDemo";
import useCreateVoiceChatRoom from "../../hooks/useCreateVoiceChatRoom";
import useParticipants from "../../hooks/useParticipants";
import useParticipantsPanel from "../../hooks/useParticipantsPanel";
import VoxeetSDK from "@voxeet/voxeet-web-sdk";
import { Participant } from "@voxeet/voxeet-web-sdk/types/models/Participant";
import { UserContext } from "../../context/UserProvider/UserProvider";
import { CommsContext } from "../../context/CommsProvider/CommsProvider";
import { useNavigate } from "react-router-dom";
import getInfoParticipant from "../../helpers/getInfoParticipant";
//TODO: нужен рефакторинг

function MultiClientBroadcastDemo() {
  return (
    <div className="row">
      <BroadcastDemo className="has-border" />
      <BroadcastDemo className="has-border" />
      <BroadcastDemo className="has-border" />
      <BroadcastDemo className="has-border" />
    </div>
  );
}

const Demo: FC = () => {
  const navigate = useNavigate();
  const [currentDemoValue, setCurrentDemoValue] = useState<string>("ThreejsDemo");

  const {
    currentRoomId,
    setCurrentRoomId,
    userName,
    userId,
    setUserName,
    changeUserIdHandler,
    rotation,
    setRotation,
    position,
    setPosition,
  } = useContext(UserContext);

  const {
    setVolumePosition,
    volumePosition,
    isNew,
    setIsNew,
  } = useContext(CommsContext);

  function handleDemoSelector(e: ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    setCurrentDemoValue(e.target.value);
  }
  const { isOpen, openPanelHandler, closePanelHandler } = useParticipantsPanel();

  const {
    rejoinGroupHandler,
    leaveGroupHandler,
    joinInfo,
    setJoinInfo
  } = useCreateVoiceChatRoom({
    roomId: currentRoomId,
    myCoordinates: volumePosition,
    userId,
    userName,
    setIsNew,
  });

  useEffect(() => {
    const x = 250 + position[0] < 0 ? 0 : 250 + position[0];
    const y = 250 + position[1] < 0 ? 0 : 250 + position[1];
    const z = 250 + position[2] < 0 ? 0 : 250 + position[2];
    setVolumePosition({ x, y, z })
  }, [position[0], position[1], position[2]])

  const redirectMeetHandler = useCallback((roomId: string) => {
    navigate({
      pathname: '/',
      search: roomId ? `?wsqMeet=${roomId}` : '',
    });
  }, []);

  const redirectHomeHandler = useCallback(() => {
    navigate('/');
  }, []);

  const { participants, participantsStatus, addIsSpeakingListener } = useParticipants({ joinInfo });
  getInfoParticipant();

  const kickParticipantHandler = useCallback((item: Participant) => {
    VoxeetSDK.conference.kick(item);
    setJoinInfo(item.id);
  }, [joinInfo]);

  const broadcastElement = currentDemoValue === "BroadcastDemo" ? <MultiClientBroadcastDemo/> : null;
  const threeElement = currentDemoValue === "ThreejsDemo" ? <div className="canvas-container">

    <ThreejsDemo
      onRedirectHome={redirectHomeHandler}
      userName={userName}
      openPanelHandler={openPanelHandler}
      rejoinGroupHandler={rejoinGroupHandler}
      roomId={currentRoomId}
      leaveGroupHandler={leaveGroupHandler}
      setRoomId={setCurrentRoomId}
      setUserId={changeUserIdHandler}
      onRedirectMeet={redirectMeetHandler}
      setUserName={setUserName}
      coordinates={position}
      setCoordinates={setPosition}
      setRotation={setRotation}
      rotation={rotation}
    />
  </div> : null;

  return (
    <div>
      <ChatPanel
        isNew={isNew}
        roomId={currentRoomId}
        userName={userName}
        onKickParticipant={kickParticipantHandler}
        addIsSpeakingListener={addIsSpeakingListener}
        participantsStatus={participantsStatus}
        participants={participants}
        isOpen={isOpen}
        onCLoseParticipant={closePanelHandler}
      />
      <select style={{ marginBottom: 20 }} value={currentDemoValue} onChange={handleDemoSelector}>
        <option value="BroadcastDemo">Broadcast demo</option>
        <option value="ThreejsDemo">Threejs demo</option>
      </select>
      {broadcastElement}
      {threeElement}
    </div>
  );
};

export default Demo;
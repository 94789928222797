import {useCallback, useState} from "react";
import {ParticipantsType} from "../containers/ChatPanel/components/Participant/Participant";

export interface useParticipantsPanelResult {
  isOpen: boolean;
  closePanelHandler: () => void;
  openPanelHandler: () => void;
}

const useParticipantsPanel = (): useParticipantsPanelResult => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closePanelHandler = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const openPanelHandler = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return {
    isOpen,
    closePanelHandler,
    openPanelHandler,
  };
};

export default useParticipantsPanel;
import { IconButton } from '@dolbyio/comms-uikit-react';
import React, { useEffect, useMemo, useState} from 'react';
import useAudio from "../../hooks/useAudio";
import useMicrophone from "../../hooks/useMicrophone";
import {Participant} from "@voxeet/voxeet-web-sdk/types/models/Participant";

type LocalToggleAudioButtonProps = {
  size?: 's' | 'm';
  participant: Participant;
};

export const LocalToggleAudioButton = ({ size = 'm', participant }: LocalToggleAudioButtonProps) => {
  const { getMicrophonePermission } = useMicrophone();
  const { isAudio, toggleAudio, isLocalAudioLoading } = useAudio();
  const [isMicrophonePermission, setIsMicrophonePermission] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const hasAccess = await getMicrophonePermission();
        setIsMicrophonePermission(hasAccess);
      } catch {
        setIsMicrophonePermission(false);
      }
    })();
  }, []);

  const iconName = useMemo(() => {
    if (isLocalAudioLoading || !isMicrophonePermission) {
      return 'microphoneOff';
    }

    if (isAudio && !isLocalAudioLoading && isMicrophonePermission) {
      return 'microphone';
    }
    return 'microphoneOff';
  }, [isAudio, isMicrophonePermission, isLocalAudioLoading]);

  const backgroundColor = useMemo(() => {
    if (isLocalAudioLoading || !isMicrophonePermission) {
      return 'grey.900';
    }

    if (isAudio && !isLocalAudioLoading && isMicrophonePermission) {
      return 'grey.800';
    }
    return 'white';
  }, [isAudio, isMicrophonePermission, isLocalAudioLoading]);

  const iconColor = useMemo(() => {
    if (isLocalAudioLoading || !isMicrophonePermission) {
      return 'grey.300';
    }

    if (isAudio && !isLocalAudioLoading && isMicrophonePermission) {
      return 'white';
    }
    return 'primary.500';
  }, [isAudio, isMicrophonePermission, isLocalAudioLoading]);

  return (
    <IconButton
      testID="ToggleMicrophoneButton"
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      icon={iconName}
      size={size}
      onClick={() => toggleAudio(participant)}
      disabled={isLocalAudioLoading || !isMicrophonePermission}
      style={{ opacity: isLocalAudioLoading || !isMicrophonePermission ? 0.8 : 1 }}
    />
  );
};

export default LocalToggleAudioButton;
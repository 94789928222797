export type Connection_id = number;
export type Room_id = number;

export interface Ws_config {
    url: string | URL;
    protocols?: string | string[];
}

export class Pc_config implements RTCConfiguration {
    iceServers: RTCIceServer[] = [{urls: ['stun:stun.l.google.com:19302']}];
}

export class Dc_config implements RTCDataChannelInit {
    label: string;
    ordered: boolean = false;
    maxPacketLifeTime: number = 100;

    constructor(label: string) {
        this.label = label;
    }
}

export class Connection_config {
    ws: Ws_config;
    dc: Dc_config;
    pc: Pc_config = new Pc_config();

    constructor(ws: Ws_config, dc: Dc_config, pc: Pc_config = new Pc_config()) {
        this.ws = ws;
        this.pc = pc;
        this.dc = dc;
    }

}

export class Connection {
    private _id!: Connection_id;
    private _ws!: WebSocket;
    private _pc: RTCPeerConnection;
    private _dc: RTCDataChannel;

    private constructor(conf: Connection_config) {
        this._pc = new RTCPeerConnection(conf.pc);
        this._dc = this._pc.createDataChannel(conf.dc.label, conf.dc as RTCDataChannelInit);
    }

    static create(conf: Connection_config): Promise<Connection> {
        const connection = new Connection(conf);
        return connection._pc.createOffer()
            .then((offer: RTCSessionDescriptionInit) => {
                console.log("Create offer");
                return connection._pc.setLocalDescription(offer);
            })
            .then(() => {
                return new Promise<void>((resolve) => {
                    if (connection._pc.iceGatheringState === "complete") {
                        console.log("ICE gathering complete");
                        resolve();
                    } else {
                        let check_state = () => {
                            if (connection._pc.iceGatheringState === "complete") {
                                connection._pc.removeEventListener("icegatheringstatechange", check_state);
                                resolve();
                            }
                        }

                        connection._pc.addEventListener("icegatheringstatechange", check_state);
                    }
                });
            })
            .then(() => {
                return new Promise<void>((resolve) => {
                    const offer = connection._pc.localDescription;
                    if (offer === null) {
                        throw new Error("Unable to create offer for RTCPeerConnection");
                    }
                    connection._dc.onopen = () => {
                        console.log("Great success");
                        resolve();
                        connection._dc.onopen = null;
                    }
                    connection._ws = new WebSocket(conf.ws.url, conf.ws.protocols);
                    connection._ws.addEventListener("open", (event: Event) => {
                        console.log("Send offer");
                        connection._ws.send(JSON.stringify({
                            sdp: offer.sdp,
                            type: offer.type,
                        }));

                    });
                    var status = false;
                    const on_ws_message = (event: MessageEvent<string>) => {

                        const answer = JSON.parse(event.data);
                        if (answer.type == "answer") {
                            console.log("Got answer");
                            connection._pc.setRemoteDescription(answer);
                            connection._id = answer.connection_id;
                            status = true;
                        } else if (status) {
                            connection._pc.addIceCandidate({
                                candidate: answer.candidate,
                                sdpMid: answer.mid,
                            });
                        }
                        // connection._ws.removeEventListener('message', on_ws_message);
                    }
                    connection._pc.onicecandidate = (event) => {
                        if (event.candidate) {
                            connection._ws.send(JSON.stringify({
                                candidate: event.candidate,
                                type: "ice-candidate",
                            }))
                        }
                    };
                    connection._ws.addEventListener('message', on_ws_message);
                });
            })
            .then(() => {
                return connection;
            }).catch((reason) => {
                console.log(reason);
                throw reason;
            });
    }

    get id() {
        return this._id;
    }

    close() {
        this._ws.close();
        this._dc.close();
        this._pc.close();
    }

    on_ws_message(listener: (ev: MessageEvent<any>) => any, options?: boolean | AddEventListenerOptions) {
        this._ws.addEventListener("message", listener, options);
    }

    on_dc_message(listener: (ev: MessageEvent<any>) => any) {
        this._dc.onmessage = listener;
    }

    send_ws(data: string | ArrayBuffer | Blob | ArrayBufferView) {
        this._ws.send(data);
    }

    send_dc(data: string | ArrayBuffer | Blob | ArrayBufferView) {
        // @ts-ignore
        this._dc.send(data);
    }
}

import fetch from '../../../utils/fetch.util';

export interface getTokenResponseType {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

const body = new URLSearchParams({grant_type: 'client_credentials'})

const getClientToken = (key?: string | null): Promise<getTokenResponseType> => {
  return fetch.post(`https://session.voxeet.com/v1/oauth2/token`, body, {
    headers: {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic YVhxMVB5eFdnb0RxWUd4MVpnVTZLZz09OmY5SHlzXzVuSDhqR2k4a2JCOVY4OGdrNklOcl9DeGxZWFN2VnFKVVhuTVk9'
    },
  });
};

export default getClientToken;
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import {useState, useEffect, useContext} from 'react';
import {CommsContext} from "../context/CommsProvider/CommsProvider";


const useParticipants = ({ joinInfo }: any) => {
  const [participants, setParticipants] = useState(new Map());

  const {
    participantsStatus,
    addIsSpeakingListener
  } = useContext(CommsContext);


  useEffect(() => {

    if (!joinInfo) {
      return;
    }

    const updateParticipants = () => {
      setParticipants(new Map(VoxeetSDK.conference.participants));
    };

    updateParticipants();

    VoxeetSDK.conference.on('streamAdded', updateParticipants);
    VoxeetSDK.conference.on('streamUpdated', updateParticipants);
    VoxeetSDK.conference.on('streamRemoved', updateParticipants);
    VoxeetSDK.conference.on('participantUpdated', updateParticipants);

    return () => {
      VoxeetSDK.conference.off('streamAdded', updateParticipants);
      VoxeetSDK.conference.off('streamUpdated', updateParticipants);
      VoxeetSDK.conference.off('streamRemoved', updateParticipants);
      VoxeetSDK.conference.off('participantUpdated', updateParticipants);
    };
  }, [joinInfo]);

  return {
    participants,
    participantsStatus,
    addIsSpeakingListener,
  };
};

export default useParticipants;
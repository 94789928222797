import { FC, useState } from "react";
import {Participant} from "@voxeet/voxeet-web-sdk/types/models/Participant";
import ParticipantComponent from './components/Participant/Participant';
import ParticipantInfo from "./components/Participant/ParticipantInfo/ParticipantInfo";
import ParticipantSettings from "./components/Participant/ParticipantSettings/ParticipantSettings";
import Drawer from '../../components/Drawer/Drawer';
import PanelHeader from "../../components/Drawer/PanelHeader";
import Participants from "../../components/Drawer/Participants";
import {ParticipantStatus} from "../../types/Participant";
import TextChat from "./components/TextChat/TextChat";
import useCreateTextChatRoom from "../../hooks/useCreateTextChatRoom";
import Tab from "../../components/Tab/Tab";
import TabModule from "../../components/Tab/TabModule";
import TabPanel from "../../components/Tab/TabPanel";

export interface ChatPanelProps {
  participants: Map<string, Participant>;
  isOpen: boolean;
  userName: string;
  roomId: string;
  onCLoseParticipant: () => void;
  participantsStatus: {[p: string]: ParticipantStatus};
  addIsSpeakingListener: (participant: Participant) => () => void;
  onKickParticipant: (item: Participant) => void;
  isNew: boolean;
}

const ChatPanel: FC<ChatPanelProps> = (props) => {
  const {
    participants,
    isOpen = false,
    roomId,
    onCLoseParticipant = () => undefined,
    onKickParticipant = () => undefined,
    participantsStatus,
    addIsSpeakingListener,
    userName,
    isNew = false,
  } = props;

  const [tabIndex, setTabIndex] = useState<number>(1);
  const participantArray: Participant[] = []

  const {
    chatClient,
    channel,
    initializeChat,
  } = useCreateTextChatRoom({ userId: userName, roomId });

  participants.forEach((value) => {
    if (value.status !== 'Kicked' && value.status !== 'Left') participantArray.push(value);
  });

  return (
    <Drawer isOpen={isOpen}>
      <PanelHeader
        tabIndex={tabIndex === 1}
        closePanelHandler={onCLoseParticipant}
        participantsCount={participantArray.length}
      />
      <Tab
        width="100%"
        direction="vertical"
      >
        <TabModule
          width="100px"
          isSelected={tabIndex === 1}
          onChangeContent={() => setTabIndex(1)}
          text='Menu'
        />
        <TabModule
          width="100px"
          isSelected={tabIndex === 2}
          onChangeContent={() => setTabIndex(2)}
          text='Chat'
        />
      </Tab>
      <TabPanel
        index={tabIndex}
        tabIndex={1}
      >
        <Participants>
          {participantArray.map((item) => {
              return (
                <ParticipantComponent key={item.id}>
                  <ParticipantInfo name={item.info.name}/>
                  <ParticipantSettings
                    isNew={isNew}
                    addIsSpeakingListener={addIsSpeakingListener}
                    participantsStatus={participantsStatus}
                    onKickParticipant={() => onKickParticipant(item)}
                    participant={item}
                  />
                </ParticipantComponent>)
            }
          )}
        </Participants>
      </TabPanel>
      <TabPanel index={tabIndex} tabIndex={2}>
        <TextChat
          chatClient={chatClient}
          channel={channel}
          initializeChat={initializeChat}
        />
      </TabPanel>
    </Drawer>
  );
};

export default ChatPanel;
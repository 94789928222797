import VoxeetSDK from "@voxeet/voxeet-web-sdk";

const getInfoParticipant = () => {
    const updateParticipantInfo = async () => {
      const response = await VoxeetSDK.session.participant;
      if (response && !response.info.name && VoxeetSDK.session.participant.status === 'Kicked') {
        window.location.reload();
      }
    };

    updateParticipantInfo();
    VoxeetSDK.conference.on('updateParticipant', getInfoParticipant);
};

export default getInfoParticipant;
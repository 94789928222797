import { useMemo, useState} from 'react';
import { Room_id } from '../Pluto';
import VoxeetSDK  from "@voxeet/voxeet-web-sdk";
import {SpatialAudioStyle} from "@voxeet/voxeet-web-sdk/types/models/SpatialAudio";

export interface UseCreateVoiceChatRoomResult {
  rejoinGroupHandler: () => void;
  leaveGroupHandler: () => void;
  joinInfo: string;
  setJoinInfo: (value: string) => void;
  finishRoomId?: string;
}

export interface UseCreateVoiceChatRoomType {
  userName: string;
  userId: string;
  roomId: Room_id | null | string;
  myCoordinates: {
    x: number,
    y: number,
    z: number,
  };
  setIsNew: (status: boolean) => void;
}

const useCreateVoiceChatRoom = (props: UseCreateVoiceChatRoomType): UseCreateVoiceChatRoomResult => {
  const {
    roomId = '',
    userName = '',
    userId = '',
    myCoordinates,
    setIsNew = () => undefined,
  } = props;

  const [joinInfo, setJoinInfo] = useState<string>('');

  const room_id = useMemo(() => {
    if (roomId !== null) return roomId.toString();
    return '';
  }, [roomId]);

  const rejoinGroupHandler = async () => {
    await VoxeetSDK.session.open({
      name: userName,
      externalId: userId,
      ownerId: userId,
    });

    const createOptions = {
      alias: room_id,
      params: {
        spatialAudioStyle: 'shared' as SpatialAudioStyle,
      }
    };

    const conference = await VoxeetSDK.conference.create(createOptions);
    setJoinInfo(VoxeetSDK.session.participant.id);
    setIsNew(conference.isNew);

    const joinOptions = {
      spatialAudio: true
    };

    await VoxeetSDK.conference.join(conference, joinOptions);

    VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, myCoordinates);
  }

  const leaveGroupHandler = async () => {
    VoxeetSDK.conference.leave();
  };

  return {
    rejoinGroupHandler,
    leaveGroupHandler,
    setJoinInfo,
    joinInfo,
  };
};

export default useCreateVoiceChatRoom;
import type { ConferencePermission, ConferenceStatus } from '@voxeet/voxeet-web-sdk/types/models/Conference';
import type Conference from '@voxeet/voxeet-web-sdk/types/models/Conference';
import type ConferenceOptions from '@voxeet/voxeet-web-sdk/types/models/ConferenceOptions';
import type { JoinOptions } from '@voxeet/voxeet-web-sdk/types/models/Options';
import type { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import { createContext, useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import conferenceService from '../../services/conference';
import VoxeetSDK from "@voxeet/voxeet-web-sdk";
import {CommsContextType} from "./CommsProviderType";
import {SpatialPosition} from "@voxeet/voxeet-web-sdk/types/models/SpatialAudio";

type DolbyIoWindow = {
  dolbyio: {
    isInitialized: boolean;
  };
}

const dioWindow = window as Window & typeof globalThis & DolbyIoWindow;
dioWindow.dolbyio = {
  isInitialized: false,
};

export interface CommsProviderProps {
  children: ReactNode;
};

export const CommsContext = createContext<CommsContextType>({} as CommsContextType);

const CommsProvider = ({ children }: CommsProviderProps) => {
  const [participant, setParticipant] = useState<CommsContextType['participant']>(null);
  const [conference, setConference] = useState<CommsContextType['conference']>(null);
  const [participantsStatus, setParticipantsStatus] = useState<CommsContextType['participantsStatus']>({});
  const [conferenceStatus, setConferenceStatus] = useState<CommsContextType['conferenceStatus']>(null);
  const [participants, setParticipants] = useState<Map<string, Participant>>(new Map());
  const [isAudio, setIsAudio] = useState<boolean>(true);
  const [isVideo, setIsVideo] = useState<boolean>(true);
  const [isLocalVideoLoading, setIsLocalVideoLoading] = useState(false);
  const [isLocalAudioLoading, setIsLocalAudioLoading] = useState(false);
  const [volumePosition, setVolumePosition] = useState<NonNullable<SpatialPosition>>({
    x: 250,
    y: 250,
    z: 250,
  });
  const [isNew, setIsNew] = useState<boolean>(false);

  useEffect(() => {
    const map: CommsContextType['participantsStatus'] = {};
    setParticipantsStatus((prev) => {
      participants.forEach((p) => {
        map[p.id] = {
          isSpeaking: !!participantsStatus[p.id]?.isSpeaking,
          isLocal: p.id === participant?.id,
          isRemoteAudio: p.id === participant?.id ? p.audioTransmitting : p.audioReceivingFrom,
          isLocalAudio: prev[p.id] ? prev[p.id].isLocalAudio : true,
          isVideo: p.streams[p.streams.length - 1]?.getVideoTracks().length > 0,
        };
      });
      return map;
    });
  }, [participants, participant]);


  const startParticipantAudio = async (participant: Participant): Promise<void> => {
    const p = conferenceService.participants().get(participant.id);
    if (p) {
      await conferenceService.startAudio(p);
      setParticipantsStatus((participantsStatus) => {
        return {
          ...participantsStatus,
          [participant.id]: {
            ...participantsStatus[participant.id],
            isLocalAudio: true,
          },
        };
      });
    }
  };

  const setParticipiantHandler = useCallback((item: any) => {
    setParticipant(item)
  }, []);

  const stopParticipantAudio = async (participant: Participant): Promise<void> => {
    const p = conferenceService.participants().get(participant.id);
    if (p) {
      await conferenceService.stopAudio(p);
      setParticipantsStatus((participantsStatus) => {
        return {
          ...participantsStatus,
          [participant.id]: {
            ...participantsStatus[participant.id],
            isLocalAudio: false,
          },
        };
      });
    }
  };

  const toggleAudio = async (participant: Participant): Promise<void> => {
    if (participant) {
      const localUser = conferenceService.participants().get(participant.id);
      if (localUser) {
        if (localUser.audioTransmitting) {
          setIsLocalAudioLoading(true);
          await VoxeetSDK.conference.stopAudio(localUser)
          setIsLocalAudioLoading(false);
          setIsAudio(false);
        } else {
          setIsLocalAudioLoading(true);
          await startParticipantAudio(localUser);
          setIsLocalAudioLoading(false);
          setIsAudio(true);
        }
      }
    }
  };

  const toggleVideo = async (): Promise<void> => {
    if (participant) {
      const localUser = conferenceService.participants().get(participant.id);
      if (localUser) {
        if (localUser.streams[localUser.streams.length - 1]?.getVideoTracks().length > 0) {
          setIsLocalVideoLoading(true);
          await conferenceService.stopVideo(participant);
          setIsLocalVideoLoading(false);
          setIsVideo(false);
        } else {
          setIsLocalVideoLoading(true);
          await conferenceService.startVideo(participant);
          setIsLocalVideoLoading(false);
          setIsVideo(true);
        }
      }
    } else {
      setIsVideo((video) => !video);
    }
  };

  const createConference = useCallback((conferenceOptions: ConferenceOptions): Promise<Conference> => {
    return conferenceService.create(conferenceOptions);
  }, []);

  const joinConference = useCallback(async (conference: Conference, joinOptions: JoinOptions): Promise<Conference> => {
    const joinedConference = await conferenceService.join(conference, joinOptions);
    setConference(joinedConference);
    return joinedConference;
  }, []);

  const leaveConference = useCallback(async (): Promise<void> => {
    await conferenceService.leave();
    setConference(null);
    setParticipantsStatus({});
    setConferenceStatus(null);
    setParticipants(new Map());
  }, []);

  const resetVideo = () => {
    setIsVideo(true);
  };

  const resetAudio = () => {
    setIsAudio(true);
  };

  const addIsSpeakingListener = (participant: Participant) => {
    const interval = setInterval(() => {
      conferenceService.isSpeaking(participant, (speakingState: boolean) => {
        setParticipantsStatus((participantsStatus) => {
          const status = participantsStatus[participant.id];
          if (status && status?.isSpeaking !== speakingState) {
            return {
              ...participantsStatus,
              [participant.id]: {
                ...status,
                isSpeaking: speakingState,
              },
            };
          }
          return participantsStatus;
        });
      });
    }, 500);
    return () => {
      clearInterval(interval);
    };
  };

  const onConferenceStatusChange = (status: ConferenceStatus) => {
    setConferenceStatus(status);
  };

  const onParticipantsChange = (participant: Participant) => {
    setParticipants((participants) => {
      const p = participants.get(participant.id);
      return new Map(
        participants.set(participant.id, {
          ...p,
          ...participant,
          audioReceivingFrom: participant.audioReceivingFrom,
        } as Participant),
      );
    });
  };

  const onStreamsChange = (participant: Participant) => {
    setParticipants((participants) => {
      const p = participants.get(participant.id);
      if (p) {
        return new Map(
          participants.set(participant.id, {
            ...p,
            ...participant,
            audioReceivingFrom: participant.audioReceivingFrom,
          } as Participant),
        );
      }
      return participants;
    });
  };

  const onPermissionsChange = (permissions: ConferencePermission[]) => {
    console.log('PERMISSIONS UPDATED EVENT DATA: \n', JSON.stringify(permissions, null, 2));
  };

  useEffect(() => {
    if (participants.size > 0) VoxeetSDK.conference.setSpatialPosition(VoxeetSDK.session.participant, volumePosition);
  }, [volumePosition]);

  useEffect(() => {
    const unsubscribers: Array<() => void> = [
      conferenceService.onConferenceStatusChange(onConferenceStatusChange),
      conferenceService.onParticipantsChange(onParticipantsChange),
      conferenceService.onStreamsChange(onStreamsChange),
      conferenceService.onPermissionsChange(onPermissionsChange),
    ];
    return () => {
      unsubscribers.forEach((u) => u());
    };
  }, []);

  const contextValue: CommsContextType = useMemo(
    () => ({
      createConference,
      setVolumePosition,
      joinConference,
      leaveConference,
      participant,
      volumePosition,
      conference,
      conferenceStatus,
      participants: Array.from(participants.values()),
      participantsStatus,
      isAudio: participant?.id && participantsStatus[participant.id] ? !!participantsStatus[participant.id]?.isRemoteAudio : isAudio,
      toggleAudio,
      startParticipantAudio,
      stopParticipantAudio,
      isVideo: participant?.id && participantsStatus[participant.id] ? !!participantsStatus[participant.id]?.isVideo : isVideo,
      toggleVideo,
      addIsSpeakingListener,
      isLocalVideoLoading,
      isLocalAudioLoading,
      resetVideo,
      setIsNew,
      isNew,
      resetAudio,
      setParticipiantHandler,
    }),
    [
      participant,
      conference,
      participants,
      participantsStatus,
      conferenceStatus,
      isAudio,
      isVideo,
      isLocalVideoLoading,
      isLocalAudioLoading,
      isNew,
    ],
  );

  return (
    <CommsContext.Provider value={contextValue}>{children}</CommsContext.Provider>
  );
};

export default CommsProvider;
import {createContext, ReactNode, useCallback, useMemo, useState} from 'react';
import {UserProviderType} from "./UserProviderType";

export interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserProviderType>({} as UserProviderType);

const UserProvider = ({ children }: UserProviderProps) => {
  const [userName, setUserName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [position, setPosition] = useState<number[]>([0, 0, 0]);
  const [rotation, setRotation] = useState<number[]>([0, 0, 0]);
  const [currentRoomId, setCurrentRoomId] = useState<string>('');

  const changeUserIdHandler = useCallback((userId: string, id: string) => {
    const newId = userId + id;
    setUserId(newId);
  }, [userId]);

  const defaultValue: UserProviderType = useMemo(() => {
    return {
      userName,
      userId,
      position,
      rotation,
      setRotation,
      setPosition,
      setUserName,
      changeUserIdHandler,
      currentRoomId,
      setCurrentRoomId,
    }
  }, [
    userName,
    userId,
    currentRoomId,
    position,
    rotation,
  ]);

  return (
    <UserContext.Provider value={defaultValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
import {FC, ReactNode} from 'react';
import classNames from './Drawer.module.css';
import PanelHeader from './PanelHeader';
import Participants from "./Participants";
import { Drawer } from "@mui/material";

export type VoiceChatPanelType = {
  PanelHeader: typeof PanelHeader;
  Participants: typeof Participants;
}

export interface VoiceChatPanelProps {
  isOpen: boolean;
  children: ReactNode;
}

const VoiceChatPanel: FC<VoiceChatPanelProps> & VoiceChatPanelType = (props) => {
  const { isOpen = false, children } = props;

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
    >
      <div className={classNames.participants}>
        {children}
      </div>
    </Drawer>
  );
};

VoiceChatPanel.PanelHeader = PanelHeader;
VoiceChatPanel.Participants = Participants;

export default VoiceChatPanel;
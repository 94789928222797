import * as pluto from "./Pluto";
import {useEffect, useRef} from "react";
import {Connection_id, Room_id} from "./Pluto";

export interface IUsePlutoClientCallbacks {
    on_error: (ev: any) => any;
    on_joined_room: (client_id: Connection_id, room_id: Room_id, participants: Connection_id[]) => any;
    on_left_room: (client_id: Connection_id) => any;
    on_ws_message: (ev: any) => any;
    on_dc_message: (ev: any) => any;
}

export function usePlutoClient(config: pluto.Connection_config, {
    on_error,
    on_joined_room,
    on_left_room,
    on_ws_message,
    on_dc_message
}: IUsePlutoClientCallbacks) {
    const client = useRef<pluto.Client | null>(null);

    useEffect(() => {
        pluto.Client.create(config).then((c: pluto.Client) => {
            client.current = c;
            client.current.on_error(on_error);
            client.current.on_joined_room(on_joined_room);
            client.current.on_left_room(on_left_room);
            client.current.on_ws_message(on_ws_message);
            client.current.on_dc_message(on_dc_message);
        });
        return () => {
            client.current?.close();
        };
    }, [config, on_error, on_joined_room, on_left_room, on_ws_message, on_dc_message]);
    return client;
}
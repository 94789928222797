import {FC} from "react";
import {TextField, TextFieldProps} from "@mui/material";
import classNames from './InputError.module.css';

export interface InputErrorProps {
  errorMessage: string;
}

export type InputErrorType =  InputErrorProps & TextFieldProps;

const InputError: FC<InputErrorType> = (props) => {
  const {
    error,
    value,
    label,
    size,
    name,
    type,
    errorMessage,
    onChange
  } = props;

  return (
    <div className={classNames.input}>
      <TextField
        aria-readonly={true}
        error={error}
        value={value}
        label={label}
        size={size}
        name={name}
        type={type}
        onChange={onChange}
      />
      {error && <div className={classNames.error}>{errorMessage}</div>}
    </div>
  );
};

export default InputError;
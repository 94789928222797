import * as THREE from "three";
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import * as pluto from "./Pluto";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {OrthographicCamera, useGLTF} from '@react-three/drei'
import {PointerLockControls, Sky} from "@react-three/drei";
import {Connection_id} from "./Pluto";
import './ThreejsDemo.css';
import classNames from './ThreeDemo.module.css';
import Modal from "./components/Modal/Modal";
import {Button} from "@mui/material";
import {IconButton} from "@dolbyio/comms-uikit-react";
import InputError from "./components/ErrorInput/InputError";
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {VRM} from "@pixiv/three-vrm";
import {DoubleSide, Object3D} from "three";

interface IThreejsDemoProps {
    roomId: string;
    setRoomId: (value: string) => void;
    rejoinGroupHandler: () => void;
    leaveGroupHandler: () => void;
    setUserId: (value: string, id: string) => void;
    setUserName: (value: string) => void;
    onRedirectHome: () => void;
    openPanelHandler: () => void;
    userName: string;
    onRedirectMeet: (roomId: string) => void;
    coordinates: number[];
    setCoordinates: (position: number[]) => void;
    rotation: number[];
    setRotation: (rotation: number[]) => void;
}

function useKeyPress(targetKey: string) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    // If pressed key is our target key then set to true
    function downHandler({key}: WindowEventMap["keydown"]) {
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }

    // If released key is our target key then set to false
    const upHandler = ({key}: WindowEventMap["keyup"]) => {
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };
    // Add event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
}

interface IPlayerMovedEvent {
    position: number[];
    rotation: number[];
}

interface IPlayerProps {
    selector?: string;
    onMoved?: (ev: IPlayerMovedEvent) => any;
}

function Player(props: IPlayerProps) {
    const wPressed = useKeyPress('w');
    const aPressed = useKeyPress('a');
    const sPressed = useKeyPress('s');
    const dPressed = useKeyPress('d');
    const ctl = useRef<any>();
    const { scene, camera } = useThree();

    const object = new Object3D();

    object.add(camera);
    scene.add(object);
    object.position.y = 1.6;
    const speed = 0.1;
    useFrame(({camera, clock}) => {
        if (wPressed) {
            ctl.current.moveForward(speed);
        }
        if (aPressed) {
            ctl.current.moveRight(-speed);
        }
        if (sPressed) {
            ctl.current.moveForward(-speed);
        }
        if (dPressed) {
            ctl.current.moveRight(speed);
        }
        if (props.onMoved) {
            props.onMoved({
                position: camera.position.toArray(),
                rotation: camera.rotation.toArray(),
            });
        }
    });

    return (
      <PointerLockControls ref={ctl} selector={props.selector} />
    )
}

interface IOtherPlayerProps {
    position: THREE.Vector3;
    rotation: THREE.Euler;
    color: string;
}

interface IOtherPlayer {
    id: Connection_id;
    position: THREE.Vector3;
    rotation: THREE.Euler;
}

function OtherPlayer(props: IOtherPlayerProps) {
    const gltf = useGLTF('/models/Male.vrm');
    const avatar = useRef<VRM>();
    const { camera } = useThree();
    useEffect(() => {
        if (gltf) {
            VRM.from(gltf as unknown as GLTF).then((vrm) => {
                avatar.current = vrm;
            });
        };
    }, [camera]);

    return (
      <mesh position={props.position} rotation={props.rotation}>
          <primitive object={gltf.scene}></primitive>
      </mesh>
    )
}

function ThreejsDemo(props: IThreejsDemoProps | Readonly<IThreejsDemoProps>) {
    const {
        rejoinGroupHandler = () => undefined,
        roomId = '',
        setRoomId = () => undefined,
        setUserName = () => undefined,
        setUserId = () => undefined,
        leaveGroupHandler = () => undefined,
        openPanelHandler = () => undefined,
        onRedirectHome = () => undefined,
        userName = '',
        onRedirectMeet = () => undefined,
        rotation,
        setRotation = () => undefined,
        setCoordinates = () => undefined,
        coordinates,
    } = props;

    const search = window.location.search;

    const [players, setPlayers] = useState<IOtherPlayer[]>([]);
    const [isJoin, setIsJoin] = useState<boolean>(false);
    const [roomId1, setRoomId1] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [showParticipants, setShowParticipants] = useState<boolean>(false);

    const client = useRef<pluto.Client | null>(null);

    function sendPosition() {
        client.current?.broadcast_room_dc_from({
            id: client.current?.id,
            "position": coordinates,
            "rotation": rotation,
        });
    }

    useEffect(() => {
        const config = new pluto.Connection_config({url: "wss://server.wondersouq.web3dev.group"}, new pluto.Dc_config("chat"));
        pluto.Client.create(config).then((c: pluto.Client) => {
            client.current = c;
            client.current.on_error((ev) => {
                if (ev.error === "room-does-not-exist") {
                    setRoomId('');
                }
            });
            client.current.on_joined_room((client_id, room_id, participants) => {
                if (client_id === client.current?.id) {
                    let otherPlayers: IOtherPlayer[] = participants.filter((id) => {
                        return id !== client_id
                    }).map((id) => {
                        return {
                            id: id,
                            position: new THREE.Vector3(0, 0, 0),
                            rotation: new THREE.Euler(0, 0, 0),
                        };
                    });
                    setPlayers(otherPlayers);
                    setRoomId1(room_id?.toString() || '');
                    sendPosition();
                } else {
                    setPlayers((prevState) => {
                        return [...prevState, {
                            id: client_id,
                            position: new THREE.Vector3(0, 0, 0),
                            rotation: new THREE.Euler(0, 0, 0),
                        }];
                    });
                }
            });
            client.current.on_left_room((client_id) => {
                console.log("left", client_id);
                setPlayers([]);
            });
            client.current.on_ws_message((ev) => {
                console.log("ws", ev);
            });
            client.current.on_dc_message((ev) => {
                setPlayers((prevState) => {
                    const index = prevState.findIndex((p) => {
                        return p.id === ev.body.message.id;
                    });
                    prevState[index] = {id: ev.body.message.id, position: ev.body.message.position, rotation: ev.body.message.rotation};
                    return [...prevState];
                });
            });
        })
        return () => {
            client.current?.close();
        };
    }, []);

    function onMoved(e: IPlayerMovedEvent) {
        setCoordinates(e.position);
        setRotation(e.rotation);
        sendPosition();
    }

    useEffect(() => {
        const queryRoomId = search.split('=')[1];
        if (queryRoomId) setRoomId(queryRoomId);
        else if (roomId1) setRoomId(roomId1);
    }, [search, roomId1])

    function join_room() {
        rejoinGroupHandler();
        setIsOpen(false);
        setIsJoin(true)
        client.current?.join_room(Number(roomId));
        onRedirectMeet(roomId);
        setShowParticipants(true);
    }

    function leave_room() {
        leaveGroupHandler();
        setIsOpen(false);
        setIsJoin(false);
        client.current?.leave_room();
        onRedirectHome();
        window.location.reload();
        setShowParticipants(false);
    }

    const closeModalHandler = useCallback(() => {
        setIsOpen(false)
    }, [isOpen]);

    const openModalHandler = useCallback(() => {
        setIsOpen(true)
    }, [isOpen]);

    const settingsJoinRoom = {
        isValidRoomId: roomId.toString().length > 1,
        isValidUserName: userName.length > 1,
        roomIdValue: roomId.toString(),
        userName,
    }

    const changeNameHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUserId(event.target.value, client.current?.id.toString() || '');
        setUserName(event.target.value);
    }, [client.current?.id]);

    const reloadHandler = useCallback(() => {
        window.location.reload();
    }, []);

    return (
      <div id="root">
          {showParticipants && <div style={{position: 'absolute', top: 1, right: 30}}>
              <IconButton
                variant="circle"
                icon="participants"
                size="s"
                onClick={openPanelHandler}
              />
          </div>}
          <Button onClick={openModalHandler}>Open</Button>
          <Button variant="outlined" id="plat-btn">Play</Button>
          <Modal onCLoseModal={closeModalHandler} isOpen={isOpen} >
              <div className={classNames.controlPanel} id="control-panel">
                  <div
                    onClick={reloadHandler}
                    className={classNames.statistics}>
                      <div className={classNames.statisticsBLock}>Current room id: {roomId}</div>
                  </div>
                  <div className={classNames.inputBlocks}>
                      <div className={classNames.input}>
                          <InputError
                            size="small"
                            value={settingsJoinRoom.roomIdValue}
                            id="room-input"
                            name="room-input"
                            label="room id"
                            type="text"
                            error={!settingsJoinRoom.isValidRoomId}
                            errorMessage="Invalid room id wait or restart site"
                          />
                      </div>
                      <div className={classNames.input}>
                          <InputError
                            value={settingsJoinRoom.userName}
                            label="Your name"
                            size="small"
                            id="name-input"
                            name="name-input"
                            error={!settingsJoinRoom.isValidUserName}
                            type="text"
                            onChange={changeNameHandler}
                            errorMessage="more than 2 letters and only latin letters"
                          />
                      </div>
                  </div>
                  <div className={classNames.actionsBLock}>
                      {!isJoin && <Button type="button" disabled={settingsJoinRoom.isValidRoomId && !settingsJoinRoom.isValidUserName} onClick={join_room}>Join
                          room</Button>}
                      {isJoin && <Button type="button" onClick={leave_room}>Leave room</Button>}
                  </div>

                  <ul className="participants" id="participants">
                      {players.map(item => (
                        <li key={item.id}>{item.id}</li>
                      ))}
                  </ul>
                  <div className={classNames.description}>
                      <span>Available room connects automatically</span>
                      {!settingsJoinRoom.isValidRoomId && <span>Please wait...</span>}
                      {settingsJoinRoom.isValidRoomId && <span>Enter your name and start chatting</span>}
                  </div>
              </div>
          </Modal>
          <div id="control-panel">
              <ul className="participants" id="participants">
                  {players.map(item => (
                    <li key={item.id}>{item.id}</li>
                  ))}
              </ul>
          </div>
          <Canvas>
              <Player selector="#plat-btn" onMoved={onMoved}/>
              <Sky/>
              <color attach="background" args={[new THREE.Color(0xa0a0a0)]}/>
              <fog args={[new THREE.Color(0xa0a0a0), 10, 50]}/>
              <hemisphereLight args={[0xffffff, 0x444444]} position={[0, 20, 0]}/>
              <directionalLight position={[-3, 10, -10]} castShadow>
                  <orthographicCamera attach="shadow-camera" args={[-2, 2, 2, -2, 0.1, 40]}></orthographicCamera>
              </directionalLight>
              <mesh rotation={new THREE.Euler(-Math.PI / 2)} receiveShadow>
                  <planeGeometry args={[100, 100]}/>
                  <meshPhongMaterial color={0xd60909} depthWrite={false}/>
              </mesh>
              <mesh position={[0, -0.2, 0]} rotation={[Math.PI / 2, 0, 0]} scale={[1000, 1000, 1000]}>
                  <planeBufferGeometry />
                  <meshBasicMaterial color="#d4d2d2" side={DoubleSide} />
              </mesh>
              {
                  players.map((player) => {
                      return <OtherPlayer color="#03a9fc" key={player.id} position={player.position} rotation={player.rotation}/>
                  })
              }
          </Canvas>
      </div>
    );
}

export default ThreejsDemo;

import axios from 'axios';

const defaultOptions = {
  timeout: 10000,
};

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response),
);

export default axiosApiInstance;

import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './BroadcastDemo.css';
import * as pluto from './Pluto'
import {Connection_id, Room_id} from "./Pluto";
import {usePlutoClient, IUsePlutoClientCallbacks} from "./usePlutoClient";

interface IBroadcastDemoProps {
    className?: string | undefined;
}

interface IMessage {
    method: string;
    body: string;
}


function BroadcastDemo(props: IBroadcastDemoProps) {
    const [wsMsgText, setWsMsgText] = useState('');
    const [dcMsgText, setDcMsgText] = useState('');
    const [wsText, setWsText] = useState<IMessage[]>([]);
    const [dcText, setDcText] = useState<IMessage[]>([]);
    const [roomInput, setRoomInput] = useState('');
    const [showWrongRoomMsg, setShowWrongRoomMsg] = useState(false);
    const [roomId, setRoomId] = useState<pluto.Room_id | null>(null);
    const [roomParticipants, setRoomParticipants] = useState<pluto.Connection_id[]>([]);

    const config = useRef(new pluto.Connection_config({url: "ws://localhost:8080"}, new pluto.Dc_config("chat")));
    const callbacks = useRef<IUsePlutoClientCallbacks>(
        {
            on_error: (ev: any) => {
                if (ev.error === "room-does-not-exist") {
                    setShowWrongRoomMsg(true);
                }
            },
            on_joined_room: (client_id: pluto.Connection_id, room_id: pluto.Room_id, participants: pluto.Connection_id[]) => {
                setRoomInput(room_id.toString());
                setRoomId(room_id);
                setRoomParticipants(participants);
            },
            on_left_room: (client_id: pluto.Connection_id) => {
                setRoomParticipants((prevParticipants) => {
                    return prevParticipants.filter((id) => id !== client_id);
                });
            },
            on_ws_message: (data: any) => {
                setWsText((prevText) => {
                    return [...prevText, {
                        method: data.event,
                        body: data.body.message,
                    }];
                });
            },
            on_dc_message: (data: any) => {
                setDcText((prevText) => {
                    return [...prevText, {
                        method: data.event,
                        body: data.body.message,
                    }];
                });
            },
        }
    );
    const client = usePlutoClient(config.current, callbacks.current);

    function join_room() {
        client.current?.join_room(Number(roomInput));
    }

    function leave_room() {
        client.current?.leave_room();
    }

    function broadcast_ws() {
        client.current?.broadcast_ws(wsMsgText);
    }

    function broadcast_ws_from() {
        client.current?.broadcast_ws_from(wsMsgText);
    }

    function broadcast_room_ws() {
        if (roomId != null) {
            client.current?.broadcast_room_ws(roomId, wsMsgText);
        }
    }

    function broadcast_room_ws_from() {
        client.current?.broadcast_room_ws_from(wsMsgText);
    }

    function broadcast_dc() {
        client.current?.broadcast_dc(dcMsgText);
    }

    function broadcast_dc_from() {
        client.current?.broadcast_dc_from(dcMsgText);
    }

    function broadcast_room_dc() {
        if (roomId != null) {
            client.current?.broadcast_room_dc(roomId, dcMsgText);
        }
    }

    function broadcast_room_dc_from() {
        client.current?.broadcast_room_dc_from(dcMsgText);
    }

    function handle_ws_change(e: ChangeEvent<HTMLInputElement>) {
        setWsMsgText(e.target.value);
    }

    function handle_dc_change(e: ChangeEvent<HTMLInputElement>) {
        setDcMsgText(e.target.value);
    }

    function handle_room_input_change(e: ChangeEvent<HTMLInputElement>) {
        setRoomInput(e.target.value);
        setShowWrongRoomMsg(false);
    }

    return (
        <div className={"cols " + props.className}>
            <div>
                <div>Client id: {client.current?.id} | Current room: {roomId}</div>
                <label htmlFor="room-input">Room: </label>
                <input id="room-input" name="room-input" type="text" onChange={handle_room_input_change}/>
                <button type="button" onClick={join_room}>Join room</button>
                <button type="button" onClick={leave_room}>Leave room</button>

                {showWrongRoomMsg && <div>Wrong room id!</div>}

                <ul className="participants" id="participants">
                    {roomParticipants.map(item => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div>
            <div>
                <div>
                    <button type="button" onClick={broadcast_ws}>Broadcast</button>
                    <button type="button" onClick={broadcast_ws_from}>Broadcast from</button>
                    <button type="button" onClick={broadcast_room_ws}>Broadcast room</button>
                    <button type="button" onClick={broadcast_room_ws_from}>Broadcast room from</button>
                </div>
                <label htmlFor="ws-message">WS message:</label>
                <input id="ws-message" name="ws-message" type="text" onChange={handle_ws_change}/>

                <ul className="in-msg" id="ws-in">
                    {wsText.map((m, i) => (
                        <li key={i}>{m.method} {"=>"} {m.body}</li>
                    ))}
                </ul>
            </div>

            <div>
                <div>
                    <button type="button" onClick={broadcast_dc}>Broadcast</button>
                    <button type="button" onClick={broadcast_dc_from}>Broadcast from</button>
                    <button type="button" onClick={broadcast_room_dc}>Broadcast room</button>
                    <button type="button" onClick={broadcast_room_dc_from}>Broadcast room from</button>
                </div>
                <label htmlFor="dc-message">DC message:</label>
                <input id="dc-message" name="dc-message" type="text" onChange={handle_dc_change}/>

                <ul className="in-msg" id="dc-in">
                    {dcText.map((m, i) => (
                        <li key={i}>{m.method} {"=>"} {m.body}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default BroadcastDemo;